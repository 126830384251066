// extracted by mini-css-extract-plugin
export var wrapper = "information-module--wrapper--2LfbF";
export var title = "information-module--title--1CWlG";
export var gray = "information-module--gray--2Q5kG";
export var content = "information-module--content--cz3g6";
export var bio = "information-module--bio--26hrB";
export var clients = "information-module--clients--12ipZ";
export var contact = "information-module--contact--2hktQ";
export var secondh2 = "information-module--secondh2--5gzfi";
export var flex = "information-module--flex--3wM23";
export var flexInner = "information-module--flexInner--3DSv0";
export var social = "information-module--social--3DYVz";
export var space = "information-module--space--2FauM";